const Footer = () => {
  return (
    <>
      <div className="h-auto italics bg-black text-gray-400 ">
        <div className="flex justify-center items-center p-2">
          This Site is developed and maintained by Ayush Sharma.
        </div>
      </div>
    </>
  );
};
export default Footer;
